// import React fr
import React from "react"
import { Link } from "gatsby"

import { Navbar, Nav } from "react-bootstrap"

class CollectionNavbar extends React.Component {
  render(){
    return(
      <Navbar variant="light" expand="md" id="article-navbar">
        {/* <Container> */}
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mr-auto">
            <Link to="/collections/" className="navbar-link">
              <Nav.Link as="span" eventKey="login">
                <h4 className="navbar-menu-item">Back</h4>
              </Nav.Link>
            </Link>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    )
  }
}
export default CollectionNavbar
