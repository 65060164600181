import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import { graphql } from 'gatsby'
import CollectionLayout from "../components/collectionLayout"
import SEO from "../components/seo"
import { Row, Col, Container, Carousel } from "react-bootstrap"
import {isMobile} from 'react-device-detect';

class CollectionPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      counter: -1,
    };
    this.incrementCounter = this.incrementCounter.bind(this);
    this.decrementCounter = this.decrementCounter.bind(this);
    this.resetCounter = this.resetCounter.bind(this);
  }

  incrementCounter() {
    this.setState({counter: this.state.counter + 1});
    if (this.state.counter === this.props.data.contentfulCollection.collectionImages.length-1) {
      this.setState({counter: -1});
    }
  }

  decrementCounter() {
    this.setState({counter: this.state.counter - 1});
    if (this.state.counter === -1) {
      this.setState({counter: this.props.data.contentfulCollection.collectionImages.length-1});
    }
  }

  resetCounter() {
    this.setState({counter: -1});
  }

  render() {

    return(
      <React.Fragment>
        <CollectionLayout pageInfo={{ pageName: this.props.data.contentfulCollection.collectionTitle }}>
          <SEO title={this.props.data.contentfulCollection.collectionTitle} keywords={[this.props.data.contentfulCollection.collectionTitle]} />
          <Container className="collection-page">
            <Row className="unhistoried-collection-content-row">
              <Col md={2} sm={12} xs={12} className="my-auto mx-auto">
                <div className="arrow previous" onClick={this.decrementCounter}></div>
              </Col>
              <Col md={8} className="my-auto">
                <Row className="unhistoried-collection-clickable" onClick={this.incrementCounter}>
                  <Col md={12} >
                    {this.state.counter === -1 && <div className="unhistoried-collection-description" dangerouslySetInnerHTML={{ __html: this.props.data.contentfulCollection.collectionDescription.childMarkdownRemark.html.replace(/href/g, "target='_blank' href") }}></div>}
                    {this.state.counter >= 0 && this.state.counter < this.props.data.contentfulCollection.collectionImages.length &&
                      <React.Fragment>
                        <GatsbyImage imgStyle={{ objectFit: 'contain' }} className="collection-image" image={this.props.data.contentfulCollection.collectionImages[this.state.counter].gatsbyImageData} alt="unhistoried"/>
                        <h5 className="unhistoried-collection-image-description">{this.props.data.contentfulCollection.collectionImages[this.state.counter].description}</h5>
                      </React.Fragment>
                    }
                  </Col>
                </Row>
              </Col>
              <Col md={2} className="my-auto">
                <div className="arrow float-right" onClick={this.incrementCounter}></div>
              </Col>
            </Row>
          </Container>
        </CollectionLayout>
      </React.Fragment>
    )
  }
}


export const CollectionPageQuery = graphql`
query CollectionDataQuery($collectionTitle: String!) {
  contentfulCollection(
    collectionTitle: {eq: $collectionTitle}
  ) {
    collectionTitle
    collectionDescription {
      childMarkdownRemark {
        html
      }
    }
    collectionImages {
      gatsbyImageData(layout: FULL_WIDTH, placeholder: TRACED_SVG)
      description
    }
  }
}`

export default CollectionPage
